<template>
  <div v-if="landingPage">
        <div class="row review-admin-contain">
          <div class="col-md-4 col-lg-4 col-xl-3 gate-left">
            <ValidationObserver
                v-if="landingPage"
                v-slot="{ handleSubmit, invalid, touched }"
                ref="updateForm"
            >
              <form
                  class="authentication-form"
                  @submit.prevent="handleSubmit(updateLandingPage)"
              >
                <ul class="list-unstyled mt-2">
              <li>
                <label class="d-flex align-items-center">Title<LeadMachineMergeFieldsGroup dropdown-size="sm" :show-keyword="true" :show-contact-link="true" :hide-title="true" button-variant="outline-light" :hide-google-links="true" class="ml-2" @selected="addField" /></label>
                <TextInput v-model="landingPage.title" class="w-100" margin-bottom="7px" />
                <TextInput v-model="landingPage.video_url" label="Video Embed URL" class="w-100" margin-bottom="7px" />
                <label class="d-flex align-items-center">Form Header<LeadMachineMergeFieldsGroup dropdown-size="sm" :show-keyword="true" :show-contact-link="true" :hide-title="true" button-variant="outline-light" :hide-google-links="true" class="ml-2" @selected="addField" /></label>
                <TextInput v-model="landingPage.form_header" class="w-100" margin-bottom="7px" />
                <label class="d-flex align-items-center">Button Text<LeadMachineMergeFieldsGroup dropdown-size="sm" :show-keyword="true" :show-contact-link="true" :hide-title="true" button-variant="outline-light" :hide-google-links="true" class="ml-2" @selected="addField" /></label>
                <TextInput v-model="landingPage.button_text" class="w-100" margin-bottom="7px" />
                <label>Address</label>
                <textarea v-model="landingPage.address" class="form-control" />
                <div class="d-inline-flex mt-3">
                  <b-form-checkbox
                      v-model="landingPage.show_review"
                      size="sm"
                      name="initial-button"
                      switch
                      inline
                  >
                  </b-form-checkbox>
                  <span class="d-flex align-items-center">Show Review</span>

                </div>
                <div>
                  <div class="d-inline-flex mt-3">
                    <b-form-checkbox
                        v-model="landingPage.show_redirect"
                        size="sm"
                        name="initial-button"
                        switch
                        inline
                    >
                    </b-form-checkbox>
                    <span class="d-flex align-items-center">Redirect Form Sumission</span>

                  </div>
                </div>
                <div v-if="landingPage.show_redirect" class="mt-2">
                  <TextInput v-model="landingPage.redirect_url" label="Redirect URL" />
                </div>
                <b-form-group class="mt-3">
                  <b-button
                      variant="success"
                      class=""
                      :disabled="isBusy || (invalid && touched)"
                      type="submit"
                  >
                    <b-spinner v-if="isBusy" small />
                    <span v-else>Update</span>
                  </b-button>
                </b-form-group>
              </li>
            </ul>
              </form>
            </ValidationObserver>
          </div>
          <div class="col-md-8 col-lg-8 col-xl-9 pr-lg-0">
            <div class="row">
              <div class="col">
                <leads-landing-page :key="new Date().getSeconds()" :landing-page="landingPage" :landing-business="landingBusiness" />
              </div>
            </div>
          </div>
        </div>

  </div>

</template>

<script>

import Vue from "vue";
import LeadsLandingPage from "@components/Agency/LeadsLandingPage.vue";
import LeadMachineMergeFieldsGroup from "@components/Agency/LeadMachineMergeFieldsGroup.vue";
export default {
  components:{
    LeadMachineMergeFieldsGroup,
    LeadsLandingPage
  },
  data(){
    return {
        user:null,
        landingPage: null,
        loadingUpdate:false,
        isBusy:false,
        landingBusiness:{
          business_name:'Test',
          rating:'5.0',
          review_count:116,
          primary_category:'Dentist',
          address:'123 Somewhere',
          business_phone:'555-555-5555',
          pretty_phone:'(555) 555-5555',
          single_best_review:{
            authorAttribution:{
              photoUri:''
            },
            text:{
              text:'Review Text'
            }
          }
        }
    }
  },
  computed:{
    gatePreUrl(){
      return window.location.origin.includes('staging.') ? 'https://stagingapi.replyengine.io/review/' : 'https://app.showmyrep.io/r/';
    },
    gateUrl(){
      return this.gatePreUrl+this.business.business.unique_id;
    }
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    this.getWidget();

  },
  methods:{
    addField(field){
      document.execCommand('insertText', false, field)
    },
    getWidget() {
      this.$store
          .dispatch('leads/getLandingPage', this.$route.params.id)
          .then((res) => {
            this.$set(this,'landingPage',res.data);
          })
          .catch((error) => {
            if(error.response.status){
              this.status = error.response.status;
            }
          })
    },
    updateLandingPage(){
      this.isBusy = true
      this.$store
          .dispatch('leads/saveLandingPage', this.landingPage)
          .then(() => {
            this.isBusy = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.updateForm.setErrors(err.response.data.errors)
            }
            this.isBusy = false
          })
    }
  }
}
</script>
<style>
.review-admin-contain{
  margin-top: -10px;
  margin-left:-24.5px;
  min-height: calc(100vh - 72px);
  margin-bottom: 0;
}
body{
  padding-bottom: 0;
}
.gate-left{
  background: #284373;
  color:#fff;
}
.gate-left li{
  background:#52688f;
  border-radius: 8px;
  padding: 5px;
  margin-bottom: 10px;
}
.eyebtn{
  margin-top: -5px;
}
</style>
